import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const Navbar = ({ siteTitle }) => (
  <nav className="navbar">
    <div className="navbar__container">
      <Link to="/" className="navbar__image">
        <StaticImage
          className="navbar__icon"
          src="../../static/images/logo.png"
          alt={siteTitle}
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </Link>
      <ul className="navbar__list">
        <a href="/#skills" title="My Skills" className="navbar__link navbar__link--active">
          <li>Skills</li>
        </a>
        <a href="/#tools" title="Tools I use" className="navbar__link">
          <li>Tools</li>
        </a>
        <a href="/#repositories" title="Usefull repositories" className="navbar__link">
          <li>Repositories</li>
        </a>
        <a href="/#resources" title="Usefull resources" className="navbar__link">
          <li>Resources</li>
        </a>
      </ul>
    </div>
  </nav>
)

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
